// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-vh-pages-404-js": () => import("./../../../src/VH/pages/404.js" /* webpackChunkName: "component---src-vh-pages-404-js" */),
  "component---src-vh-pages-about-us-js": () => import("./../../../src/VH/pages/about-us.js" /* webpackChunkName: "component---src-vh-pages-about-us-js" */),
  "component---src-vh-pages-blog-js": () => import("./../../../src/VH/pages/blog.js" /* webpackChunkName: "component---src-vh-pages-blog-js" */),
  "component---src-vh-pages-contact-js": () => import("./../../../src/VH/pages/contact.js" /* webpackChunkName: "component---src-vh-pages-contact-js" */),
  "component---src-vh-pages-index-js": () => import("./../../../src/VH/pages/index.js" /* webpackChunkName: "component---src-vh-pages-index-js" */),
  "component---src-vh-pages-ppc-js": () => import("./../../../src/VH/pages/ppc.js" /* webpackChunkName: "component---src-vh-pages-ppc-js" */),
  "component---src-vh-pages-privacy-policy-js": () => import("./../../../src/VH/pages/privacy-policy.js" /* webpackChunkName: "component---src-vh-pages-privacy-policy-js" */),
  "component---src-vh-pages-redirect-js": () => import("./../../../src/VH/pages/redirect.js" /* webpackChunkName: "component---src-vh-pages-redirect-js" */),
  "component---src-vh-pages-terms-of-service-js": () => import("./../../../src/VH/pages/terms-of-service.js" /* webpackChunkName: "component---src-vh-pages-terms-of-service-js" */),
  "component---src-vh-templates-region-js": () => import("./../../../src/VH/templates/Region.js" /* webpackChunkName: "component---src-vh-templates-region-js" */)
}

